.form-label {
  text-transform: capitalize;
  padding: 0px !important;
  margin-bottom: 0px !important;
  margin-top: 15px !important;
}
.error {
  color: red;
}
/* .form-control{
    margin: 0 ;
    background-color: red !important;
}
.input-group{
    margin: 0 !important;
    background-color: green !important;

} */
.card {
}
.card-subtitle {
  font-size: 12px;
}
th {
  text-transform: capitalize;
}
label {
  color: rgb(119, 119, 119);
}
/* bootstrap styles */
input:disabled {
  background-color: transparent !important;
  margin-left: -12px;
  border: 1px solid rgb(238, 245, 243);
}

textarea:disabled {
  background-color: transparent !important;
  margin-left: -12px !important;
  border: 1px solid rgb(238, 245, 243);
}

select:disabled {
  background-color: transparent !important;
  margin-left: -12px;
  border: none;
  border: 1px solid rgb(238, 245, 243);
}
.nav-tabs .nav-item .nav-link {
  border: 0px solid #0080ff;
  color: #686868 !important;
  border-radius: 0 !important;
}
.nav-tabs .nav-item .nav-link.active {
  color: #232423 !important;
  /*  border-bottom: 2px solid #0080FF; */
  background: transparent !important;
  border-radius: 0 !important;
}

.nav-pills .nav-item .nav-link {
  color: #757575 !important;
}

.nav-pills .nav-item .nav-link.active {
  color: #e6e4e4 !important;
  background: rgb(0, 4, 255) !important;
  /*  border-bottom: 2px solid #2c2c2c; */
  border-radius: 20px !important;
}

.tab-content > .tab-pane:not(.active) /* , .pill-content > .pill-pane:not(.active) */ {
  display: block !important;
  height: 0 !important;
  overflow-y: hidden !important;
}
.table-responsive {
  border-radius: 0px !important;
}
/* date range picker */
/* .rdrDefinedRangesWrapper {
    display: none;
  } */

.form-control:focus {
  border-color: rgb(0, 4, 255) !important;
  box-shadow: 0px 0px 0px rgb(0, 4, 255) inset, 0px 0px 0px rgb(0, 4, 255) !important;
}
.form-select:focus {
  border-color: rgb(0, 4, 255) !important;
  box-shadow: 0px 0px 0px rgb(0, 4, 255) inset, 0px 0px 0px rgb(0, 4, 255) !important;
}
