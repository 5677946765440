.home-layout-container {
    min-height: 100vh;
    background: #f5f5f5;
}

.signin-container,
.signup-container,
.resetlink-container,
.resetpassword-container {
    justify-content: center;
    /*   display: flex;
    align-items: center; */
    padding-top: 10vh;
    height: 100vh;
}
th{
    color: #505050;
}
/*pagiantion*/
.item {
    align-items: center;
    color: rgb(59, 59, 59);
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 30px;
    justify-content: center;
    width: 30px;

}

.disabled-page {
    color: #808e9b;
}

.active {
    border: solid 1px #808e9b;
    border-radius: 5px;
    color: #808e9b;
}

.break-me {}

.next {
    color: rgb(201, 30, 30) !important;
    margin-left: 10px;
    text-decoration: none !important;
}

.pagination {
    /* background-color: #0fbcf9; */
    /* justify-content: center; */
    /* display: inline-block !important; */
    list-style-type: none;
}

.pagination-page {}
.previousLinkClassName{
    text-decoration: none !important;
    color: rgb(223, 10, 10) !important;
}
.previous {
    color: rgb(150, 23, 23) !important;
    margin-right: 30px;
   
}
.page-link{
    border: 0px solid red !important;
    background-color:transparent !important;
    color: rgb(43, 42, 42) !important;
}
.actives{
    background-color: rgb(228, 228, 230) !important;
    color: rgb(216, 216, 218) !important;
    border: 0px solid red !important;
    border-radius: 5px;
}
/* date range picker */
/* .rdrDefinedRangesWrapper {
    display: none;
  } */