/* Googlefont Poppins CDN Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
/* scrollbar */
/* width */
.sidebar::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
h1 {
}
h2 {
  font-weight: 500 !important;
  font-size: 20px !important;
}
h3 {
  font-weight: 500 !important;
  font-size: 15px !important;
}
h5 {
  font-size: 14px !important;
}
.table-responsive{
  background-color: rgb(255, 255, 255) !important;
}
thead {
  background-color: #cbcbcf !important;
  color: rgb(212, 211, 211) !important;
  border-radius: 10px !important;
 
  /* border-bottom: #FFD700 !important; */
  padding-top: 5px !important;
  padding-top: 20px !important;
}
th {
  text-transform: capitalize;
  color: rgb(116, 115, 115) !important;
}
.log_out {
  margin-top: 200px !important;
}
.btn-primary,
.btn-primary:active,
.btn-primary:visited {
  color: #616161 !important;
  background-color: #ffffff !important;
  border-color: #e2e2cf !important;

  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s ease 0s;
}
.btn-primary:hover {
  color: #2242f5 !important;
  /* background-color: #444444 !important; */
  border-color: #ffffff !important;
}
.nav-link {
}
.form-control,
.form-select {
  border-radius: 5px !important;
}

.btn {
}
/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

label {
  color: rgb(119, 119, 119);
}
/* bootstrap styles */
input:disabled {
  background-color: transparent !important;
  margin-left: -12px;
  border: none;
  border-bottom: 1px solid rgb(197, 197, 197);
}

textarea:disabled {
  background-color: transparent !important;
  margin-left: -12px;
  border: none;
  border-bottom: 1px solid rgb(197, 197, 197);
}

select:disabled {
  background-color: transparent !important;
  margin-left: -12px;
  border: none;
  border-bottom: 1px solid rgb(197, 197, 197);
}

/* circle div */
.circle {
  width: 500px;
  height: 500px;
  line-height: 500px;
  border-radius: 50%;
  font-size: 50px;
  color: #fff;
  text-align: center;
  background: #000;
}
/* text wrap */
.textwrap {
  overflow: hidden;
  max-width: 15ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;

  /*   max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}

/* status color */
.activestatus {
  color: green;
}

.inactivestatus {
  color: red;
}

.sidebar {
  position: fixed;
  height: 100%;
  width: 240px;
  background: rgb(0, 17, 255) !important;
  transition: all 0.5s ease;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar.active {
  width: 60px;
  border: 0px solid red !important;
}

.sidebar .logo-details {
  height: 80px;
  display: flex;
  align-items: center;
}

.sidebar .logo-details i {
  font-size: 28px;
  font-weight: 500;
  color: #fff;
  min-width: 60px;
  text-align: center;
}

.sidebar .logo-details .logo_name {
  color: #fff !important;
  font-size: 24px;
  font-weight: 500;
}

.sidebar .nav-links {
  margin-top: 10px;
  padding-left: 0%;
}

.sidebar .nav-links li {
  position: relative;
  list-style: none;
  height: 50px;
}

.sidebar-nav-links {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
}

.sidebar-nav-active {
  background: #0505053f;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 50px 0px 0px 50px !important;
}

.sidebar-nav-links:hover {
  background: #0505053f;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 50px 0px 0px 50px !important;
}

.sidebar .nav-links li i {
  min-width: 60px;
  text-align: center;
  font-size: 18px;
  color: rgb(233, 235, 236) !important;
}

.sidebar .nav-links li .links_name {
  color: rgb(233, 235, 236) !important;


  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
}

.sidebar .nav-links .sidenav-submenu i {
  min-width: 60px;
  text-align: center;
  font-size: 18px;
  color: rgb(233, 235, 236) !important;
  ;
}

.sidebar .nav-links .sidenav-submenu a {
  height: 50px;
}

.sidenavcollapese {
  padding-left: 10px;
}

.sidebar .nav-links .sidenav-submenu .links_name {
  color: rgb(233, 235, 236) !important;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
}

.sidebar .nav-links .log_out {
  bottom: 0;
  width: 100%;
}

.home-section {
  position: relative;
  background: #f3f4f7;
  min-height: 100vh;
  width: calc(100% - 240px);
  left: 240px;
  transition: all 0.5s ease;
}

.sidebar.active ~ .home-section {
  width: calc(100% - 60px);
  left: 60px;
}

.home-section nav {
  display: flex;
  justify-content: space-between;
  height: 80px;
  background: #fff;
  display: flex;
  align-items: center;
  position: fixed;
  width: calc(100% - 240px);
  left: 240px;
  z-index: 100;
  padding: 0 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
}

.sidebar.active ~ .home-section nav {
  left: 60px;
  width: calc(100% - 60px);
}

.home-section nav .sidebar-button {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
}

nav .sidebar-button i {
  font-size: 35px;
  margin-right: 10px;
}

.home-section nav .search-box {
  position: relative;
  height: 50px;
  max-width: 550px;
  width: 100%;
  margin: 0 20px;
}

nav .search-box input {
  height: 100%;
  width: 100%;
  outline: none;
  background: #f5f6fa;
  border: 2px solid #efeef1;
  border-radius: 6px;
  font-size: 18px;
  padding: 0 15px;
}

nav .search-box .bx-search {
  position: absolute;
  height: 40px;
  width: 40px;
  background: #2697ff;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 22px;
  transition: all 0.4 ease;
}

.home-section nav .notify-details {
  flex: 1;
  text-align: right;
  padding: 0 15px 0 2px;
  color: #949292;
}

nav .notify-details a {
  margin-right: 25px;
  color: #949292 !important;
}

.home-section nav .profile-details {
  display: flex;
  align-items: center;
  /*  background: #6073c2; */
  border: 2px solid #efeef1;
  border-radius: 6px;
  height: 50px;
  min-width: 160px;
  padding: 0 2px 0 2px;
}

nav i {
  font-size: 25px !important;
}

nav .profile-details {
  font-size: 18px !important;
}

nav .profile-details a {
  text-decoration: none;
}

nav .profile-details img {
  height: 40px !important;
  width: 40px !important;
  border-radius: 6px;
  object-fit: cover;
}

nav .profile-details .admin_name {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin: 0 10px;
}
nav {
  background-color: #ffffff !important;
}
nav .profile-details i {
  font-size: 15px !important;
  color: #333;
}

.notification-container {
  position: relative;
  width: 16px;
  height: 16px;
  left: 15px;
}

.notification-counter {
  position: absolute;
  top: -2px;
  left: 15px;
  background-color: rgba(212, 19, 13, 1);
  color: #fff;
  border-radius: 3px;
  padding: 1px 3px;
  font: 8px Verdana;
}

.home-section .home-content {
  position: relative;
  /*  padding-top: 104px; */
  padding: 104px 15px;
}

/* Responsive Media Query */
@media (max-width: 1240px) {
  .sidebar {
    width: 60px;
  }

  .sidebar.active {
    width: 220px;
    border: 0px solid red !important;
  }

  .sidenavcollapese {
    padding-left: 0;
  }

  .home-section {
    width: calc(100% - 60px);
    left: 60px;
  }

  .sidebar.active ~ .home-section {
    left: 220px;
    width: calc(100% - 220px);
    overflow: hidden;
  }

  .home-section nav {
    width: calc(100% - 60px);
    left: 60px;
  }

  .sidebar.active ~ .home-section nav {
    width: calc(100% - 220px);
    left: 220px;
  }
}

@media (max-width: 1150px) {
}

@media (max-width: 1000px) {
}

@media (max-width: 700px) {
  nav .sidebar-button .dashboard,
  nav .profile-details .admin_name,
  nav .profile-details i {
    display: none;
  }

  .home-section nav .profile-details {
    height: 50px;
    min-width: 40px;
  }
}

@media (max-width: 550px) {
  .sidebar {
    width: 0;
  }

  .sidebar.active {
    width: 220px;
  }

  .sidenavcollapese {
    padding-left: 0;
  }

  .home-section {
    width: 100%;
    left: 0;
  }

  .sidebar.active ~ .home-section {
    left: 220px;
    width: calc(100% - 220px);
  }

  .home-section nav {
    width: 100%;
    left: 0;
  }

  .sidebar.active ~ .home-section nav {
    left: 220px;
    width: calc(100% - 220px);
  }
}
